.job-details-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    z-index: 1000; /* Make sure it's on top of other elements */
  }
  
  .job-details-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:whitesmoke;
    padding: 20px;
    border-radius: 10px;
    z-index: 1001; /* Above the semi-transparent background */
    text-align: center;
    min-width: 40vw;
    max-width: 60vw;
  }
  
  .job-details-content textarea{
    width: 35vw;
  }

  .d-btn{
    margin-top: 2%;
    background-color: antiquewhite;
    border-radius: 15px;
    border-width: 1px;
    }