
.wrapper{
    padding-bottom: 100px;
}

.charts-container{
    display: flex;
    flex-direction: row;
    gap: 400px;
    justify-content: center;
    width: 100%;
}

.charts-container{
    padding-top: 2%;
}


.pie-chart-container{
    max-height: 100vh;
    max-width: 100%;
    position: relative;
}

.bar-chart-container {
    max-height: 100vh;
    min-width: 25%;
    max-width: 100%;
    position: relative;
}

.bar-chart-container canvas {
    min-height: 80%;
    max-height: 90%;
    max-width: 100%;
    padding: 2%;
    position: relative;
}

.pie-chart-container canvas{
    min-height: 88%;
    max-height: 90%;
    max-width: 100%;
    position: relative;
}

@media (min-width: 768px) {
    .pie-chart-container canvas, .bar-chart-container canvas{
        min-height: 10vh;
        max-height: 60vh;
    }
}

@media (min-width: 480px) {
    .pie-chart-container canvas, .bar-chart-container canvas{
        min-height: 10vh;
        max-height: 50vh;
    }
}

@media (min-width: 280px) {
    .pie-chart-container canvas, .bar-chart-container canvas{
        min-height: 10vh;
        max-height: 50vh;
    }
}